import { render, staticRenderFns } from "./grouping.vue?vue&type=template&id=597010dd&scoped=true&"
import script from "./grouping.vue?vue&type=script&lang=js&"
export * from "./grouping.vue?vue&type=script&lang=js&"
import style0 from "./grouping.vue?vue&type=style&index=0&id=597010dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597010dd",
  null
  
)

export default component.exports