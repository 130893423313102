<template>
  <div class="grouping">
    <!--分组管理 -->
    <div class="searchBtn">
      <el-select
        v-model="paging.name"
        placeholder="请选择分组名称"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.label"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="paging.siteId"
        placeholder="请选择场地"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in siteAllList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入姓名" v-model="paging.playerName" clearable>
      </el-input>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <!-- <el-button type="primary" size="small" @click="groupConfigClick"
        >分组配置</el-button
      > -->
      <el-button
        type="primary"
        size="small"
        @click="groupBtn"
        :loading="loading"
        >开始分组</el-button
      >
    </div>
    <div class="detailsBox" v-for="(item, index) in siteList" :key="index">
      <div>{{ item.siteCity }} — {{ item.siteName }}：</div>
      <div>参赛总人数：{{ item.playerCount }}</div>
      <div>已分组数：{{ item.groupCount }}</div>
      <div>靶位数量：{{ item.siteTargetCount }}</div>
    </div>
    <div class="mcBox">选手列表</div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        size="small"
      >
        <el-table-column prop="groupName" label="分组名称"> </el-table-column>
        <el-table-column prop="playerName" label="选手姓名"> </el-table-column>
        <el-table-column prop="playerCompany" label="单位"> </el-table-column>
        <el-table-column prop="siteCity" label="城市"> </el-table-column>
        <el-table-column prop="siteName" label="场地"> </el-table-column>
        <el-table-column prop="siteTargetName" label="靶位"> </el-table-column>
        <!-- <el-table-column prop="siteTargetNumber" label="靶位序号">
        </el-table-column> -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editGroup(scope.row)"
              >重新分组</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="paging.page"
        :page-size="paging.size"
        layout="total, prev, pager, next, jumper"
        :total="paging.total"
      >
      </el-pagination>
    </div>
    <GroupConfig
      :groupCount="groupCount"
      :siteTargetCount="siteTargetCount"
      ref="groupConfig"
      @getSelectGroup="getSelectGroup"
    ></GroupConfig>
  </div>
</template>

<script>
import { startGroup } from '@/api/eventManagement/grouping/index.js'
import { getSelectGroup, getSelectGroupCountByComtetionId, getSelectSiteDown } from '@/api/eventManagement/competitionProject/index.js'
import GroupConfig from './groupConfig.vue'
export default {
  name: 'Grouping',
  components: {
    GroupConfig
  },
  data() {
    return {
      loading: true,
      siteAllList: [],
      paging: {
        page: 1,
        size: 10,
        competitionId: parseInt(sessionStorage.getItem('competitionId')) ? parseInt(sessionStorage.getItem('competitionId')) : null,
        name: "",
        playerName: "",
        siteId: null,
        total: 0
      },
      matchType: 1,
      tableData: [
        // {
        //   competitionId: 49,
        //   competitionType: "男子10米气步枪",
        //   name: "第1组",
        //   playerId: 68,
        //   playerName: "熊大",
        //   siteTargetId: 9,
        //   siteTargetName: "1",
        //   siteTargetNumber: "靶位编号1111"
        // }
      ], // 选手列表
      // playerCount: 0,
      groupCount: 0,
      siteTargetCount: 0,
      siteList: [],
      groupList: [] // 分组列表
    }
  },
  created() {
    this.getSelectGroup();
    this.getSelectSiteDown();
  },
  methods: {
    // 打开分组配置弹层
    editGroup(val) {
      // console.log(1);
      // console.log(val)
      this.$refs.groupConfig.openAdd(val);
    },
    // 获取比赛场地
    async getSelectSiteDown() {
      const res = await getSelectSiteDown();
      // console.log(res);
      if (res.status === 200) {
        this.siteAllList = res.data;
      }
    },

    // --------------------------------------
    // 获取选手列表
    async getSelectGroup() {
      this.loading = true;
      const res = await getSelectGroup(this.paging);
      // const res = await getSelectGroup(relationId: '');
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        if (res.data.rows && res.data.rows.length > 0) {
          this.matchType = res.data.rows[0].matchType;
          this.getSelectGroupCountByComtetionId();
        }
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 分组数量
    async getSelectGroupCountByComtetionId() {
      const res = await getSelectGroupCountByComtetionId({ competitionId: this.paging.competitionId, matchType: this.matchType });
      // console.log(res);
      if (res.status === 200) {
        this.siteList = res.data;
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            this.groupCount += item.groupCount;
            this.siteTargetCount += item.siteTargetCount;
          });
          if (this.groupCount > 0) {
            this.groupList = this.generateData(this.groupCount);
          }
          // console.log(this.groupCount, this.siteTargetCount);
        }

        // this.playerCount = res.data.playerCount ? res.data.playerCount : 0;
        // this.groupCount = res.data.groupCount ? res.data.groupCount : 0;
        // this.siteTargetCount = res.data.siteTargetCount ? res.data.siteTargetCount : 0;
        // if (this.groupCount > 0) {
        //   this.groupList = this.generateData(this.groupCount);
        // }
      }
    },
    generateData(val) {
      const data = [];
      for (let i = 1; i <= val; i++) {
        data.push({
          key: i,
          label: `第${i}组`
        });
      }
      return data;
    },
    // 点击搜索
    search() {
      // console.log(this.type);
      this.paging.page = 1;
      this.getSelectGroup();
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectGroup()
    },
    // 开始分组
    async groupBtn() {

      if (sessionStorage.getItem('competitionId') === null) {
        this.$message({
          type: 'warning',
          message: '请先完善新建项目板块信息',
          showClose: true
        })
        return
      }
      this.loading = true;
      const res = await startGroup({ competitionId: this.paging.competitionId });
      // console.log(res); 
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '分组成功!'
        });
        this.getSelectGroup();
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '分组失败!' + res.msg
        });
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  // 去掉el-table的hover变色效果
  // 方法1：此方法适用没有设置固定列时
  .el-table tbody tr:hover > td {
    background-color: transparent !important;
  }
  // 方法2：此方法适用设置了固定列fixed属性后
  // .el-table__body .el-table__row.hover-row td{
  //     background-color: transparent !important;
  //     cursor: pointer;
  // }
}
.grouping {
  .searchBtn {
    // ::v-deep.el-select {
    //   margin-right: 55px;
    // }
    ::v-deep.el-input {
      width: 180px;
      // margin-right: 20px;
    }
    .btnBox1 {
      margin: 0 !important;
    }
  }
  .detailsBox {
    width: 100%;
    height: 22px;
    display: flex;
    margin-top: 22px;
    div {
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      margin-right: 103px;
    }
  }
  .mcBox {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303030;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  /* .tableContent {
    .el-table--border {
      border-bottom: 1px solid #ebeef5;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  } */

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>

